import { createApp } from "vue";
import i18n from "./i18n";
import { createVuestic } from "vuestic-ui";
import { createGtm } from "@gtm-support/vue-gtm";

import stores from "./stores";
import router from "./router";
import vuesticGlobalConfig from "./services/vuestic-ui/global-config";
import App from "./App.vue";
import axios from 'axios';
import { AxiosResponse } from "axios";
import { createYmaps } from 'vue-yandex-maps';

const HOST = "https://cycle-rider.ru";
// const HOST = "http://localhost:8000";

axios.defaults.baseURL = HOST;

const axiosAuth = axios.create ({
  baseURL : HOST,
  timeout: 60000,
});

axiosAuth.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('token') //do not store token on localstorage!!!
    config.headers.Authorization = `Bearer ${token}`;
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
);
function httpErrorHandler(error: any) {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response
    const request = error?.request
    const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === 'ERR_NETWORK') {
      console.log('connection problems..')
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..')
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status
      if (statusCode === 404) {
        console.log('The requested resource does not exist or has been deleted');
      } else if (statusCode === 401) {
        localStorage.clear();
        router.push({ name: "login" });
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
  //Something happened in setting up the request and triggered an Error
  console.log(error.message)
}

function responseHandler(response: AxiosResponse<any>) {
  return response
}

function responseErrorHandler(response: any) {
  const config = response?.config
  if (config.raw) {
    return response
  }
  // the code of this function was written in above section.
  return httpErrorHandler(response)
}
axiosAuth.interceptors.response.use(responseHandler, responseErrorHandler)
const app = createApp(App);

app.provide('axiosAuth', axiosAuth);
app.use(stores);
app.use(router);
app.use(i18n);
app.use(createVuestic({ config: vuesticGlobalConfig }));
app.use(createYmaps({
  apikey: '6f86a7b9-e51a-4708-8ac3-ac1285807fa1',
}));

app.provide('HOST', HOST);
if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  );
}

app.mount("#app");
