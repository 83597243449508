export interface INavigationRoute {
  name: string;
  displayName: string;
  meta: { icon: string };
  children?: INavigationRoute[];
}

export default {
  root: {
    name: "/",
    displayName: "navigationRoutes.home",
  },
  routes: [
    {
      name: "dashboard",
      displayName: "menu.dashboard",
      meta: {
        icon: "vuestic-iconset-dashboard",
      },
    },
    {
      name: 'workouts',
      displayName: 'menu.workouts',
      meta: {
        icon: 'folder_shared',
      },
        children: [
          {
            name: 'list_workouts',
            displayName: 'menu.list_workouts',
            children: [
              {
                name: 'workout_item',
                displayName: 'Тренировка',
              },
            ]
          },
          {
            name: 'upload_workouts',
            displayName: 'menu.upload_workouts',
          }
        ],
    },
  ] as INavigationRoute[],
};
